import React, { useRef, useState } from 'react';
import './SlideHorizontal.css'; // Necesitarás crear este archivo para estilos adicionales

import LottieAnimation from '../../Lottie';

const SlideHorizontal = () => {
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const scrollLeftButton = () => {
    sliderRef.current.scrollBy({ left: -500, behavior: 'smooth' });
  };

  const scrollRightButton = () => {
    sliderRef.current.scrollBy({ left: 500, behavior: 'smooth' });
  };

  const onMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const onMouseLeave = () => {
    setIsDragging(false);
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2; // The multiplier can be adjusted for faster/slower scroll
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  const onTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const onTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2; // The multiplier can be adjusted for faster/slower scroll
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  const onTouchEnd = () => {
    setIsDragging(false);
  };

  return (
    <div className="relative flex items-center">
      <button
        className="absolute left-0 z-10 p-2 bg-gray-300 rounded-full pc "
        onClick={scrollLeftButton}
      >
        &lt;
      </button>
      <div
        ref={sliderRef}
        className="overflow-x-hidden flex items-center justify-center  max-md:justify-start space-x-4 p-4  rounded-xl cursor-grab active:cursor-grabbing w-full"
        onMouseDown={onMouseDown}
        onMouseLeave={onMouseLeave}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
       
          <div className='flex flex-col bg-black rounded-3xl p-6 h-96 space-y-4 w-[270px]  flex-shrink-0'>
            <div className=' h-full rounded-3xl'>
            <LottieAnimation nombre='galleta' />
            </div>
            <div className='bg-blue-600 texto_estirado text-white text-3xl font-bold rounded-2xl p-3 text-center w-full'>
              dulces y bocaditos
            </div>
          </div>
          <div className='flex flex-col bg-black rounded-3xl p-6 h-96 space-y-4 w-[270px]  flex-shrink-0'>
            <div className=' h-full rounded-3xl'>
            <LottieAnimation nombre='tacita' />
            </div>
            <div className='bg-blue-600 texto_estirado text-white text-3xl font-bold rounded-2xl p-3 text-center w-full'>
              mayor preferencia
            </div>
          </div>
          <div className='flex flex-col bg-black rounded-3xl p-6 h-96 space-y-4 w-[270px]  flex-shrink-0'>
            <div className=' h-full rounded-3xl'>
            <LottieAnimation nombre='room' />
            </div>
            <div className='bg-blue-600 texto_estirado text-white text-3xl font-bold rounded-2xl p-3 text-center w-full'>
              mejores ambientes
                          </div>
          </div>
          
       
      </div>
      <button
        className="absolute right-0 z-10 p-2 bg-gray-300 rounded-full pc "
        onClick={scrollRightButton}
      >
        &gt;
      </button>
    </div>
  );
}

export default SlideHorizontal;
