import React, { useState } from 'react';
import logo from '../assets/images/logoECSA.png';
import logoTiktok from '../assets/images/tiktokLOGO.png';
import logoFace from '../assets/images/facebookLOGO.png';
import logoYoutube from '../assets/images/youtube.png';

function Navbar() {
  const [dropdownAsistenciasVisible, setDropdownAsistenciasVisible] = useState(false);
  const [dropdownPuntosVisible, setDropdownPuntosVisible] = useState(false);
  const [dropdownHistorialVisible, setDropdownHistorialVisible] = useState(false);

  const toggleDropdownAsistencias = () => {
    setDropdownAsistenciasVisible(!dropdownAsistenciasVisible);
  };
  
  const toggleDropdownPuntos = () => {
    setDropdownPuntosVisible(!dropdownPuntosVisible);
  };
  
  const toggleDropdownHistorial = () => {
    setDropdownHistorialVisible(!dropdownHistorialVisible);
  };

  return (
    <nav className="fixed bg-gray-800 text-white p-2 top-0 left-0 right-0 h-20 z-40 items-center">
      <div className="container mx-auto flex justify-between items-center">
        <div className='flex flex-row space-x-6 items-center'>
          <div className='h-16 relative hover:cursor-pointer'>
            <img src={logo} alt='Logo ECSA' className='h-full' />
          </div>
          <div className="font-semibold text-5xl texto_estirado hover:cursor-pointer">
            ECSA
          </div>
        </div>
        <div className='flex items-center'>
          <a href="/" className="text-2xl font-semibold px-3 py-2 mx-3 rounded-xl bg-black hover:bg-red-600">Inicio</a>
          <div className="relative">
            <div onClick={toggleDropdownAsistencias} className="text-2xl font-semibold px-3 py-2 mx-3 rounded-xl bg-black hover:bg-red-600 hover:cursor-pointer">
              Asistencias <span className="material-symbols-outlined">arrow_drop_down</span>
            </div>
            {dropdownAsistenciasVisible && (
              <div className="p-2 absolute flex flex-col space-y-3 left-0 mt-2 bg-gray-800 rounded-xl">
                <a href="/asistencias_sabados" className="text-2xl font-semibold px-3 py-2 mx-3 rounded-xl bg-black hover:bg-red-600 block">Asistencias Sábados</a>
                <a href="/asistencias_domingos" className="text-2xl font-semibold px-3 py-2 mx-3 rounded-xl bg-black hover:bg-red-600 block">Asistencias Domingos</a>
              </div>
            )}
          </div>
          <div className="relative">
            <div onClick={toggleDropdownPuntos} className="text-2xl font-semibold px-3 py-2 mx-3 rounded-xl bg-black hover:bg-red-600 hover:cursor-pointer">
              Puntos <span className="material-symbols-outlined">arrow_drop_down</span>
            </div>
            {dropdownPuntosVisible && (
              <div className="p-2 absolute flex flex-col space-y-3 left-0 mt-2 bg-gray-800 rounded-xl">
                <a href="/puntos_sabados" className="text-2xl font-semibold px-3 py-2 mx-3 rounded-xl bg-black hover:bg-red-600 block">Puntos Sábados</a>
                <a href="/puntos_domingos" className="text-2xl font-semibold px-3 py-2 mx-3 rounded-xl bg-black hover:bg-red-600 block">Puntos Domingos</a>
              </div>
            )}
          </div>
          <div className="relative">
            <div onClick={toggleDropdownHistorial} className="text-2xl font-semibold px-3 py-2 mx-3 rounded-xl bg-black hover:bg-red-600 hover:cursor-pointer">
              Historial Puntos <span className="material-symbols-outlined">arrow_drop_down</span>
            </div>
            {dropdownHistorialVisible && (
              <div className="p-2 absolute flex flex-col space-y-3 left-0 mt-2 bg-gray-800 rounded-xl">
                <a href="/historial_sabados" className="text-2xl font-semibold px-3 py-2 mx-3 rounded-xl bg-black hover:bg-red-600 block">Historial Puntos Sábados</a>
                <a href="/historial_domingos" className="text-2xl font-semibold px-3 py-2 mx-3 rounded-xl bg-black hover:bg-red-600 block">Historial Puntos Domingos</a>
              </div>
            )}
          </div>
          <a href="https://www.facebook.com/profile.php?id=61558000381151" target="_blank" rel="noopener noreferrer">
            <div className='rounded-full h-14 mx-1 hover:cursor-pointer'>
              <img src={logoFace} alt='facebook' className='h-full' />
            </div>
          </a>
          <a href="https://www.tiktok.com/@equipodecatequesi" target="_blank" rel="noopener noreferrer">
            <div className='rounded-full h-14 mx-1 hover:cursor-pointer'>
              <img src={logoTiktok} alt='tiktok' className='h-full' />
            </div>
          </a>
          <a href="https://www.youtube.com/@RinconDelCatequistaOficial" target="_blank" rel="noopener noreferrer">
            <div className='rounded-full h-12 mx-1 hover:cursor-pointer'>
              <img src={logoYoutube} alt='youtube' className='h-full' />
            </div>
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
