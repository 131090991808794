import React, { useState } from 'react';
import logo from '../assets/images/logoECSA.png';
import logoTiktok from '../assets/images/tiktokLOGO.png';
import logoFace from '../assets/images/facebookLOGO.png';
import logoYoutube from '../assets/images/youtube.png'


function Sidebar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const [dropdownAsistenciasVisible, setDropdownAsistenciasVisible] = useState(false);
    const [dropdownPuntosVisible, setDropdownPuntosVisible] = useState(false);
    const [dropdownHistorialVisible, setDropdownHistorialVisible] = useState(false);

    const toggleDropdownAsistencias = () => {
        setDropdownAsistenciasVisible(!dropdownAsistenciasVisible);
    };

    const toggleDropdownPuntos = () => {
        setDropdownPuntosVisible(!dropdownPuntosVisible);
    };

    const toggleDropdownHistorial = () => {
        setDropdownHistorialVisible(!dropdownHistorialVisible);
    };

    return (
        <>
            <div className={`fixed flex flex-col top-0 left-0 h-full w-64 bg-gray-800 text-white  transition-transform duration-300 z-50 ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>

                <div className='flex flex-col  h-full '>
                    <div className='relative w-full bg-slate-900 p-4  h-56 items-center flex flex-col pt-12'>

                        <button onClick={toggleSidebar} className="absolute   top-4 right-4 text-white rounded-full   hover:bg-blue-700 font-bold  ">
                            <span className="material-symbols-outlined">
                                close
                            </span>
                        </button>

                        <div className='flex   items-center mb-4 w-full  justify-between '>
                            <div className=' flex flex-row space-x-2 items-center'>

                                <div className='h-16'>
                                    <img src={logo} alt='Logo ECSA' className='h-full' />
                                </div>
                                <div className="font-semibold justify-self-end  text-white text-5xl texto_estirado hover:cursor-pointer">
                                    ECSA
                                </div>
                            </div>

                        </div>
                        <div className='absolute  bottom-10 flex space-x-2'>
                            <a href="https://www.facebook.com/profile.php?id=61558000381151" target="_blank" rel="noopener noreferrer">
                                <div className=' rounded-full h-10 mx-1 hover:cursor-pointer'> <img src={logoFace} alt='Logo ECSA' className='  h-full' /></div>
                            </a>
                            <a href="https://www.tiktok.com/@equipodecatequesi" target="_blank" rel="noopener noreferrer">
                                <div className=' rounded-full h-10 mx-1 hover:cursor-pointer'> <img src={logoTiktok} alt='Logo ECSA' className='  h-full' /></div>

                            </a>
                            <a href="https://www.youtube.com/@RinconDelCatequistaOficial" target="_blank" rel="noopener noreferrer">
                                <div className=' rounded-full h-10 mx-1 hover:cursor-pointer'> <img src={logoYoutube} alt='Logo ECSA' className='  h-full' /></div>
                            </a>

                        </div>
                        <div className='pt-16 texto_estirado'>
                            Haz click y descubre la magia
                        </div>
                    </div>

                    <div className='flex flex-col w-full p-3 space-y-5 '>

                        <a href="/" className=" text-2xl font-semibold  px-3 py-2 mx-3  rounded-xl border-b-2 border-red-600 hover:bg-red-600">Inicio</a>
                        <div className="relative">
                            <div onClick={toggleDropdownPuntos} className=" text-2xl font-semibold  px-3 py-2 mx-3 rounded-xl border-b-2 border-red-600 hover:bg-red-600 hover:cursor-pointer">Puntos <span className="material-symbols-outlined">arrow_drop_down</span>
                            </div>
                            {dropdownPuntosVisible && (
                                <div className="p-2  flex flex-col space-y-3 left-0 mt-2 bg-gray-800 rounded-xl">
                                    <a href="/puntos_sabados" className="text-2xl font-semibold px-3 py-2 mx-3 rounded-xl bg-black hover:bg-red-600 block">Puntos Sábados</a>
                                    <a href="/puntos_domingos" className="text-2xl font-semibold px-3 py-2 mx-3 rounded-xl bg-black hover:bg-red-600 block">Puntos Domingos</a>
                                </div>
                            )}
                        </div>
                        <div className="relative">
                            <div onClick={toggleDropdownAsistencias} className=" text-2xl font-semibold  px-3 py-2 mx-3 rounded-xl border-b-2 border-red-600 hover:bg-red-600 hover:cursor-pointer">Asistencias <span className="material-symbols-outlined">arrow_drop_down</span>
                            </div>
                            {dropdownAsistenciasVisible && (
                                <div className="p-2  flex flex-col space-y-3 left-0 mt-2 bg-gray-800 rounded-xl">
                                    <a href="/asistencias_sabados" className="text-2xl font-semibold px-3 py-2 mx-3 rounded-xl bg-black hover:bg-red-600 block">Asistencias Sábados</a>
                                    <a href="/asistencias_domingos" className="text-2xl font-semibold px-3 py-2 mx-3 rounded-xl bg-black hover:bg-red-600 block">Asistencias Domingos</a>
                                </div>
                            )}
                        </div>
                        <div className="relative">
                            <div onClick={toggleDropdownHistorial} className=" text-2xl font-semibold  px-3 py-2 mx-3 rounded-xl border-b-2 border-red-600 hover:bg-red-600 hover:cursor-pointer">Historial de Puntos <span className="material-symbols-outlined">arrow_drop_down</span>
                            </div>
                            {dropdownHistorialVisible && (
                                <div className="p-2  flex flex-col space-y-3 left-0 mt-2 bg-gray-800 rounded-xl">
                                    <a href="/historial_sabados" className="text-2xl font-semibold px-3 py-2 mx-3 rounded-xl bg-black hover:bg-red-600 block">Historial de Puntos Sábados</a>
                                    <a href="/historial_domingos" className="text-2xl font-semibold px-3 py-2 mx-3 rounded-xl bg-black hover:bg-red-600 block">Historial de Puntos Domingos</a>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>

            <div className='fixed top-0 left-0 z-40 w-full bg-slate-900 p-4 flex   space-x-2'>
                <button onClick={toggleSidebar} className="text-white hover:bg-blue-700 font-bold py-2 px-4 rounded">
                    <span className="material-symbols-outlined">
                        menu
                    </span>
                </button>
                <div className='flex flex-row space-x-3 items-center  w-full'>
                    <div className='h-16'>
                        <img src={logo} alt='Logo ECSA' className='h-full' />
                    </div>
                    <div className="font-semibold text-white text-5xl texto_estirado hover:cursor-pointer">
                        ECSA
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sidebar;
