import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import EsperaPage from '../EsperaPage';

export default function HistorialPuntos({ dia = "sabado" }) {
    const [asistencias, setAsistencias] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get('https://script.google.com/macros/s/AKfycbxRc8kk8-LnZ_AjbnrVDqNcQm2tp5hAdDMt1FIfkNKoTDO4cSXk7E6DVRggkpbTlWNL/exec');
                const data = response.data;
                
                let hojaHistorial;

                if (dia === "sabado") {
                    hojaHistorial = data.HISTORIAL_PUNTOS;
                } else if (dia === "domingo") {
                    hojaHistorial = data.HISTORIAL_PUNTOS_DOMINGOS;
                }

                if (hojaHistorial && hojaHistorial.length > 0) {
                    // Asignamos los datos empezando desde la segunda fila para omitir la primera
                    setAsistencias(hojaHistorial.slice(1));
                } else {
                    console.error('Datos no están definidos en la respuesta');
                    setError('Datos no están definidos en la respuesta');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Error fetching data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dia]);

    const columnTitles = [
        "ID", "FECHA", "COMUNIDAD", "DETALLE", "PUNTOS OBTENIDOS"
    ];

    if (isLoading) {
        return <div className=''><EsperaPage /></div>;
    }

    return (
        <div className='relative flex flex-col items-center w-full bg-black px-4 pt-[75px] h-screen'>
            <Navbar />
            <Sidebar />

            <div className="relative w-full overflow-x-auto shadow-md sm:rounded-lg mt-5">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="bg-gray-50 dark:bg-gray-700">
                        <tr>
                            {columnTitles.map((title, index) => (
                                <th key={index} scope="col" className="sticky top-0 px-6 py-3 text-xs text-gray-700 uppercase z-10 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    {title}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {asistencias.map((item, index) => (
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                {Object.values(item).map((value, i) => (
                                    <td key={i} className={`px-6 py-4 ${value === "FALTO / NECESITA RECUPERAR" || value === "ASISTIO / MUY TARDE / FALTO / NECESITA RECUPERAR" ? 'bg-red-400 text-white' : ''}`}>
                                        {value}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
