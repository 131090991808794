import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeContainer from './home/home_container';
import PuntosPage from './puntos/puntos_page';
import AsistenciasPage from './asistencia/AsistenciasPage';
import HistorialPuntos from './Historial_Puntos/HistorialPuntos';

function App() {
  return (
    <Router>
      
      <Routes>
        <Route path="/" element={<HomeContainer  />} />
        <Route path="/puntos_sabados" element={<PuntosPage dia='sabado' />} />
        <Route path="/asistencias_sabados" element={<AsistenciasPage dia='sabado' />} />
        <Route path="/historial_sabados" element={<HistorialPuntos dia='sabado' />} />
        <Route path="/puntos_domingos" element={<PuntosPage dia='domingo' />} />
        <Route path="/asistencias_domingos" element={<AsistenciasPage dia='domingo'/>} />
        <Route path="/historial_domingos" element={<HistorialPuntos dia='domingo' />} />

      </Routes>
    </Router>
  );
}

export default App;
