import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import ComunidadPuntos from './puntos_componentes/Comunidad_Puntos';
import { loadImage } from '../../utils/loadImage';
import SlideHorizontal from './puntos_componentes/SlideHorizontal';
import EsperaPage from '../EsperaPage';

export default function PuntosPage({dia="sabado"}) {
    const [puntos, setPuntos] = useState([]);
    const [comunidades, setComunidades] = useState([]);
    const [filaTresColCuatro, setFilaTresColCuatro] = useState('');
    const [imagenComunidad, setImagenComunidad] = useState(null);
    const [puntajeMayor, setpuntajeMayor] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get('https://script.google.com/macros/s/AKfycbxRc8kk8-LnZ_AjbnrVDqNcQm2tp5hAdDMt1FIfkNKoTDO4cSXk7E6DVRggkpbTlWNL/exec');
                const data = response.data;

                let hojaPuntajes;

                if (dia === "sabado") {
                    hojaPuntajes = data.PUNTAJES;
                } else if (dia === "domingo") {
                    hojaPuntajes = data.PUNTAJES_DOMINGO;
                }

                if (hojaPuntajes && hojaPuntajes.length > 1) {
                    const puntosData = hojaPuntajes.slice(1).map(row => row[2]);
                    const comunidadesData = hojaPuntajes.slice(1).map(row => row[1]);
                    const filaTresColCuatroData = hojaPuntajes[2][3];
                    const puntajeMayorData = hojaPuntajes[1][3];
                    setPuntos(puntosData);
                    setComunidades(comunidadesData);
                    setFilaTresColCuatro(filaTresColCuatroData);
                    setpuntajeMayor(puntajeMayorData);

                    // Load image dynamically
                    const image = loadImage(filaTresColCuatroData);
                    setImagenComunidad(image);
                } else {
                    console.error('Datos no están definidos en la respuesta');
                    setError('Datos no están definidos en la respuesta');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Error fetching data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dia]);

    if (isLoading) {
        return <div className=''><EsperaPage></EsperaPage></div>;
    }

    if (error) {
        return <div className='text-center text-white'>{error}</div>;
    }

    return (
        <div className='relative flex flex-col items-center pt-20 bg-black'>
            <div className='pc'>
                <Navbar />
            </div>
            <div className='mobile'>
                <Sidebar />
            </div>

            <div className='relative flex flex-col ancho_responsivo px-2 py-10 bg-black items-center space-y-11'>
                <div className='bg-blue-600 font-bold texto_estirado text-white rounded-3xl px-8 py-3 text-center md:text-7xl text-4xl'>
                    PUNTAJES POR COMUNIDAD
                </div>

                <div className='flex md:flex-row flex-col w-full md:space-x-6 px-6 max-md:space-y-7'>
                    <div className='flex flex-col md:w-2/3 bg-rose-800 p-6 rounded-2xl'>
                        {puntos.map((puntaje, index) => (
                            <ComunidadPuntos key={index} puntaje={puntaje} comunidad={comunidades[index]} />
                        ))}
                    </div>
                    <div className='flex flex-col md:w-1/3 max-md:py-4 bg-rose-800 px-4 rounded-2xl justify-center items-center space-y-3'>
                        <div className='h-2/3 flex w-full justify-center'>
                            {imagenComunidad && (
                                <img src={imagenComunidad} className='h-full' alt='Imagen Comunidad' />
                            )}
                        </div>
                        <div className='flex flex-row max-md:flex-col max-md:items-center max-md:space-y-4 justify-between w-full'>
                            <div className='bg-blue-600 texto_estirado text-white text-3xl   font-bold rounded-2xl p-3 text-center w-40 max-md:w-full'>
                                liderando
                            </div>
                            <div className='bg-blue-600 texto_estirado text-white text-3xl  font-bold rounded-2xl p-3 text-center w-40 max-md:w-full'>
                                {filaTresColCuatro}
                            </div>
                        </div>
                        <div className='bg-black texto_estirado text-white text-3xl font-bold rounded-2xl p-3 text-center w-full'>
                            puntaje : {puntajeMayor}
                        </div>
                    </div>
                </div>

                <div className='flex flex-col  fondo_espejo w-full p-6 space-y-4 rounded-3xl'>
                    <div className='flex flex-row max-md:flex-col max-md:items-center max-md:space-y-2 justify-between'>
                        <div className='bg-blue-600 texto_estirado text-white text-3xl font-bold rounded-2xl p-3 text-center w-40 max-md:w-full'>
                            beneficios
                        </div>
                        <div className='bg-blue-600 texto_estirado text-white text-3xl font-bold rounded-2xl p-3 text-center w-40 max-md:w-full'>
                            {filaTresColCuatro}
                        </div>
                    </div>
                    <SlideHorizontal></SlideHorizontal>
                </div>

                <div className='bg-rose-800 w-full p-3 text-center texto_estirado text-white text-6xl rounded-3xl'>
                    Ganadores de anteriores meses
                </div>
            </div>
        </div>
    );
}
