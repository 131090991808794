import React from 'react'
import LottieAnimation from './Lottie'

export default function EsperaPage() {
  return (
    <div className=' w-screen h-screen flex flex-col  justify-center bg-black '>
        <div className='h-1/3  self-center'>

        <LottieAnimation nombre={"grupo1"}></LottieAnimation>
        <div className='texto_estirado text-white text-3xl text-center py-5'>
            ESTAMOS CARGANDO TUS ARCHIVOS...
        </div>
        </div>
    </div>
  )
}
