import React from 'react'
import LottieAnimation from '../Lottie'
import './home_container.css';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';



export default function HomeContainer() {


    return (
        <div className='relative flex flex-col  items-center  contenedor '>
            <div className=' pc'>

                <Navbar />
            </div>
            <div className='mobile'>

                <Sidebar />
            </div>




            <div className="relative  contenedor flex justify-center ancho_responsivo  ">
                <div className=' flex flex-col pc '>

                    <div className='h-screen flex justify-center'>
                        <div className='self-end z-10 absolute'>
                            <LottieAnimation nombre='fuego' />
                            
                        </div>
                        <div className="relative z-20 self-end -mb-24 p-0">
                            <h1 className="texto_estirado confirmacion_titulo">
                                CONFIRMACION
                            </h1>
                        </div>
                    </div>

                    <div className=' texto_estirado my-8 text-2xl text-center text-white px-20'>
                        SOMOS UN GRUPO PARROQUIAL EXPERTOS EN LA FORMACION DEL SACRAMENTO DE LA CONFIRMACION, BRINDAMOS EL SERVICI ODE PREPARACION APRA LA CONFIRAMCION EN LA PARROQUIA SAN ANTONIO DE PADUA - PUNO
                    </div>

                    <div className=' w-full h-[50px] fondo_espejo flex flex-row justify-between text-white   items-center px-8'>
                        <div>
                            TERMINOS Y CONDICIONES
                        </div>
                        <div className=' flex  space-x-4'>
                            <div>
                                CONTACTO: 987670599
                            </div>
                            <div>
                                CORREO: EQUIPODECATEQUESISPUNO@GMAIL.COM
                            </div>
                        </div>

                    </div>


                </div>

                <div className='flex flex-col justify-center items-center w-full  mobile'>
                    <div className='w-96'>
                    <LottieAnimation nombre='fuego' />
                       
                    </div>

                    <h1 className="texto_estirado confirmacion_titulo max-md:text-7xl  text-white text-center">
                    CONFIRMACION <br/> 2024
                    </h1>

                    <div className=' texto_estirado my-8 text-2xl text-center text-white max-md:px-6 px-20'>
                        SOMOS UN GRUPO PARROQUIAL EXPERTOS EN LA FORMACION DEL SACRAMENTO DE LA CONFIRMACION, BRINDAMOS EL SERVICI ODE PREPARACION APRA LA CONFIRAMCION EN LA PARROQUIA SAN ANTONIO DE PADUA - PUNO
                    </div>

                    <div className=' w-full md:h-[50px] fondo_espejo flex flex-row max-md:flex-col justify-between text-white   items-center px-8 ' >
                        <div>
                            TERMINOS Y CONDICIONES
                        </div>
                        <div className=' flex  md:space-x-4 max-md:flex-col max-md:space-y-2'>
                            <div>
                                CONTACTO: 987670599
                            </div>
                            <div>
                                CORREO: EQUIPODECATEQUESISPUNO@GMAIL.COM
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    );
}
