import React from 'react'

export default function ComunidadPuntos({ puntaje, comunidad = "......" }) {

    return (
        <div className='flex flex-row w-full space-x-3 pb-4'>
            <div className='bg-blue-600 texto_estirado text-white text-3xl max-md:text-xl max-md:w-32 font-bold rounded-2xl p-3 text-center w-40'>
                {comunidad}
            </div>
            <div className=' flex space-x-3'>
                <div className=' texto_estirado text-white text-4xl font-bold rounded-2xl pt-3  pc '>

                    {puntaje}
                </div>

                <div
                    style={{ width: `${puntaje}px` }}
                    className='bg-black texto_estirado text-white text-4xl font-bold rounded-2xl  pc '
                >

                </div>
            </div>
            <div
                style={{ width: `${puntaje / 3}px` }}
                className='bg-black texto_estirado text-white text-1xl font-bold rounded-2xl  mobile pt-4'
            >
                {puntaje}
            </div>

        </div>
    )
}

